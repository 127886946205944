import { Component, OnInit,Renderer2 } from '@angular/core';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { MatDialog, MatDialogRef } from  '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';
import { AppService } from '../app.service'
import { SignupForm,LoginForm,OtpForm ,RegistredEmailForm,ConfirmPasswordForm} from '../data-model';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{
  closeResult = '';

signupForm: SignupForm = new SignupForm();
loginForm: LoginForm = new LoginForm();
otpForm: OtpForm = new OtpForm();
registredEmailForm: RegistredEmailForm = new RegistredEmailForm();
confirmpasswordForm: ConfirmPasswordForm = new ConfirmPasswordForm();
validate_message: string = "";
sessionWarningClass: boolean;
sessionWarningStyle: object;
WarningClass: boolean;
WarningStyle: object;
SignupClass: boolean;
SignupStyle: object;
LoginClass: boolean;
LoginStyle: object;
warning_content:any;
msg: string = "";
otpmessage: string = "";
ActualOTP: string="";
OTPClass: boolean;
OTPStyle: object;
RegisteredEmailClass: boolean;
registeredEmailStyle: object;
ConfirmPasswordClass: boolean;
confirmPasswordStyle: object;
watcher: Subscription;
LoginXStyle:object;
LoginXotpStyle:object;
imagelogostyle= {'height': '1400px'};
buttonstyle:object;
forgetpasswordotp=false;
otp_enabled="";
countryval:string = null;
countryselection:object[];
mobiledigit:number;
country_code:string;
mob_prefix:string;
mobile_input_digit:string;
password_valid=false;
params_subscription: Subscription;
odooemailid:string = "";
registered_email:string= "";

public showPassword = false;
public showPass = false;
public forgetPassword = false;
public forgetPass = false;
private renderer: Renderer2
ngOnInit(){
  this.LoginClass = true;
  this.LoginStyle = {'display': 'block'};
  $('#login-email').focus();
  $('#log-btn').click();
  $.ajax({
    url:'/web/payment_portal/getCountries/',
    method:'GET',
    data:{},
    dataType:'JSON',
    success:function(response){
      // console.log(response.data)
      var data = response.data
      for (var i=0;i<data.length;i++)
      {
          // console.log(data[i])
          $('#signup-country').append(`<option value="`+data[i].country_code+`" data-id="+`+data[i].mobile_prefix+`" data-value="`+data[i].digits+`" style="color:black!important;font-weight:semibold">
                                       `+(data[i].country_name).toUpperCase()+`
                                  </option>`);
      }
      
    }
  })  

  this.params_subscription=this.activateRoute.params.subscribe(
    (param: Params) => {
      // console.log(param,"params");
      localStorage.setItem('refby',JSON.stringify(param))
    }
  )
}

public togglePasswordVisibility():void{
  this.showPassword = !this.showPassword;
}

public showpa():void{
  this.showPass = !this.showPass;
}

public forgetpass1():void{
  this.forgetPassword = !this.forgetPassword;
}

public forget2():void{
  this.forgetPass = !this.forgetPass;
}

title = 'Kalayan Jewellers - Buy / Top-up Gift Cards';
  slides = [{'image': '/assets/images/kalyan-background.jpg'}];

  constructor(private modalService: NgbModal, public dialog: MatDialog,public spinner: NgxSpinnerService,private router: Router, mediaObserver: MediaObserver, public appService: AppService, public activateRoute:ActivatedRoute) {
  this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
       this.LoginXStyle= {'margin-right': '5%'};
       
       this.LoginXotpStyle = {'margin-right':'6%'};
       this.LoginStyle = {'max-width':'100%'};
       
      } else {
        this.LoginStyle = {'max-width':'440px'};
        this.LoginXStyle= {'margin-right': '0px'};
        
        
        this.LoginXotpStyle={'margin-right':'0%'};
       

      }
    });
  }
  open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

// checkfade(){
//   const backdrop = document.querySelector('.modal-backdrop');
//   console.log('inside fuction')
//   if (backdrop) {
//     this.renderer.removeClass(backdrop, 'modal-backdrop');
//     this.renderer.removeClass(backdrop, 'fade');
//     this.renderer.removeClass(backdrop, 'show');
//   }
// }

getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }
hideWarning = function(){
    this.sessionWarningClass = false;
    this.sessionWarningStyle = {'display': 'none'};
  }

  onNoClick = function(){
    $(".warning").html("");
    var self=this;
    self.signupForm={
      email:"",
      mobileNumber:"",
      password:"",
      confirmPassword:""
     };
     this.countryval="";
    this.SignupClass = false;
    this.SignupStyle = {'display': 'none'};
    return false;
    
  }
  tabfun = function(evt, s_type) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(s_type).style.display = "block";
  //evt.currentTarget.className += " active";
  
}

signup = function(){ 
  var self= this;
  self.signupForm={
  email:"",
  mobileNumber:"",
  password:"",
  confirmPassword:""
  };
  this.countryval="";
  this.LoginClass = false;
  this.LoginStyle = {'display': 'none'};
  this.SignupClass = true;
  this.SignupStyle = {'display': 'block'};
  $('#signup-email').focus()
  $('#id_warn_message').hide();
  $(".warning").html("");
  $('#id_confirm_warn_message').hide();
}

login = function(){
  var self=this;
  self.loginForm={
  mobileNumber:"",
  password:""
  };
  
  this.SignupClass = false;
  this.SignupStyle = {'display': 'none'};
  this.buttonstyle = {'background-color':'red'};
  this.LoginClass = true;
  this.LoginStyle = {'display': 'block'};
  $('#login-email').focus()
}
forgetpass = function(){
 var self=this;
  self.registredEmailForm={
  email:""
  };
  this.LoginClass = false;
  this.LoginStyle = {'display': 'none'};
  this.RegisteredEmailClass = true;
  this.registeredEmailStyle = {'display': 'block'};
}
onNologinClick= function(){
    var self=this;
      self.loginForm={
      mobileNumber:"",
      password:""
      };
    // console.log("jfjfjfj")
    $(".info").html("")
    this.LoginClass = false;
    this.LoginStyle = {'display': 'none'};
    return false;
  
}
onNootpclick= function(){
    var self=this;
        self.otpForm={
            otp:""
            };
    // console.log("jfjfjfj")
    $(".info").html("")
    this.OTPClass = false;
    this.OTPStyle = {'display': 'none'};
    return false;
  
}
onNoregisteredemailclick = function(){
    var self=this;
    self.registredEmailForm={
    email:""
    };
    // console.log("jfjfjfj")
    $(".warningregistredemail").html("")
    this.RegisteredEmailClass = false;
    this.registeredEmailStyle = {'display': 'none'};
    return false;
  
}
onNoconfirmpasswordclick = function(){
    var self=this;
    self.confirmpasswordForm={
    password:"",
    confirmPassword:""
    };
    $(".warningconfirmpassword").html("")
    this.ConfirmPasswordClass = false;
    this.confirmPasswordStyle = {'display': 'none'};
    return false;

  
}

OnchangeCountry = function()
{   
    this.mob_prefix = $('#signup-country option:selected').attr('data-id')
    this.mobiledigit = $('#signup-country option:selected').attr('data-value')
    this.mobile_input_digit = parseInt(this.mobiledigit)+1
    // console.log(this.mob_prefix,"prefix")
    $('#signup-mobile').val(this.mob_prefix)
}

setcountryselection(e){
  var self = this;
  // console.log(e.target.value)
  if(e.target.value =="United Arab Emirates"){
  self.countryselection=[{ title: "United Arab Emirates", value: "United Arab Emirates" }];
  }
  else if(e.target.value =="Qatar"){
  self.countryselection=[{ title: "Qatar", value: "Qatar" }];
  }
  else{
  self.countryselection=[{ title: "United Arab Emirates", value: "United Arab Emirates" }, 
  { title: "Qatar", value: "Qatar" }];
  }
}

validatePassword(password){
var pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&?@])([a-zA-Z0-9!#$%&?@]+)$/
// console.log('tttttttttttttttttt',pattern)
if (pattern.test(password)){
    return true;
}
else{
    return false;
}
}


validateEmail(mail) 
  {
    var status = false;
    var msg = ''
    if(mail)
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
          status = true;
        }
        else{
          status = false;
          msg= ' Please enter valid email ID..';
        }
    }
    else
    { 
        status = false;
        msg= 'Email Should not be empty..';
        
    }
    return {'status':status,'msg':msg};
    
  }
  validateMobileNumber(mobile)
  {
    
    var status = false
    var msg = ''
    var country = $('#signup-country').val();
    // console.log("country mobile",country)
    if (country!= null)
    {
        if(mobile!=null)
        {
            var pre = (this.mob_prefix).replace('+','')
            mobile = mobile.replace('+','')
            if (!isNaN(mobile))
            {
              if(mobile.startsWith(pre))
              {
                  if(mobile.length == this.mobiledigit)
                  {
                    status = true;
                  }
                  else
                  {
                    status = false;
                    msg = 'Mobile Number length should be '+(this.mobiledigit).toString();
                  }
              }
              else
              {
                  status = false;
                  msg = 'Mobile number should start with '+(pre).toString();
              }  
            }
            else
            {
              status = false;
              msg = "Mobile number should contain number 0 to 9.."
            }    
        }
        else
        {
            status = false;
            msg = 'Mobile Number shold not be empty..'
        }
    }
    else
    {
        status = false;
        msg = "Please choose country.."
    }
    
    return {'status':status,'msg':msg}
 
  }
passwordValidation(x){
      // console.log('conign inside password validation',x)
      var self = this;
      var password = x.target.value
      var len = password.length
      var caps=  /[A-Z]/
      var numbers = /[0-9]/
      var special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      var len_valid,up_valid,nu_valid,sp_valid=false
      if(len>=8)
      {
          $('.len-case').addClass('text-success')
          $('#len-check').show()
          len_valid=true
      }
      else
      {
          $('.len-case').removeClass('text-success')
          $('#len-check').hide()   
          len_valid=false
      }

      if(caps.test(password))
      {
          $('.up-case').addClass('text-success')
          $('#up-check').show()
          up_valid=true
      }
      else
      {
          $('.up-case').removeClass('text-success')
          $('#up-check').hide()
          up_valid=false
      }
      if(numbers.test(password))
      {
          $('.nu-case').addClass('text-success')
          $('#nu-check').show()
          nu_valid=true
      }
      else
      {
          $('.nu-case').removeClass('text-success')
          $('#nu-check').hide()
          nu_valid=false
      }
      if(special.test(password))
      {
          $('.sp-case').addClass('text-success')
          $('#sp-check').show()
          sp_valid=true
      }
      else
      {
          $('.sp-case').removeClass('text-success')
          $('#sp-check').hide()
          sp_valid=false
      }

      if(len_valid && nu_valid && up_valid && sp_valid)
      {
          self.password_valid = true
          $('#password-pattern').removeClass('alert-danger')
          $('#password-pattern').addClass('alert-success')
      }
      else
      {
          self.password_valid = false
          $('#password-pattern').removeClass('alert-success')
          $('#password-pattern').addClass('alert-danger')
      }
}

forgotpasswordValidation(x){
// console.log('conign inside password validation',x)
let passwd = x.target.value
if (passwd.length < 8 && passwd.length != 0){
var self = this;
if (x.target.id == 'id_password'){
$('#id_forget_warn_message').show();
$('#id_forget_confirm_warn_message').hide();
}
else
{
$('#id_forget_confirm_warn_message').show();
$('#id_forget_warn_message').hide();

}
}
else{
if (x.target.id == 'id_password'){
$('#id_forget_warn_message').hide();
}
else
{
$('#id_forget_confirm_warn_message').hide();
}
}
}






formSubmit(value){
      $('#signup-email-err').hide()
      var self = this;
      self.msg="";
      // console.log(value.mobile_number,value.email,value.password,value.confirm_password,self.countryval,"fdkdfknbfgbn")
      let mobile_number=value.mobile_number;
      let email=value.email;
      let password=value.password;
      let confirm_password=value.confirm_password;
      let country_code = $('#signup-country option:selected').val()
      // console.log(country_code,"country_code")
      let emailvalid = self.validateEmail(email)
      let validPassword = self.password_valid
      let mobileValidation = self.validateMobileNumber(mobile_number)
      var form_valid = true
      var err_msg = ''
      if (!emailvalid.status && form_valid == true)
      {
          form_valid = false;
          err_msg = emailvalid.msg
      }

      if (!mobileValidation.status && form_valid == true)
      {
          form_valid = false;
          err_msg = mobileValidation.msg
      }
      if(!country_code && form_valid == true)
      {
          form_valid = false;
          err_msg = 'Please select the country..'
      }
      if(!validPassword && form_valid == true)
      {
          form_valid = false;
          err_msg = 'Please enter valid password';
      }
      else
      {
          if (password != confirm_password && form_valid == true)
          {
              form_valid = false;
              err_msg = 'Confirm password should be same as password...'
          }
      }

      if(form_valid)
      {
              var session_id='';
              if(this.getCookie('kalyan_id')!=null){
                session_id=this.getCookie('kalyan_id');
              }  

              if (mobile_number || email || password || confirm_password || country_code){
              let args = {'session': session_id,'email':email,'password':password,'mobile_number':mobile_number,'confirm_password':confirm_password,'user_country':self.countryval,'country_code':country_code };
              var csrftoken = self.getCookie('csrf_token');
              this.spinner.show();  
              $.ajax({
                      beforeSend: function(xhr, settings) {
                        xhr.setRequestHeader("X-CSRFToken", csrftoken);
                      },
                      method: "POST",
                      url: "/web/payment_portal/signup/",
                      dataType: "json",
                      data : {data:JSON.stringify(args),csrf_token: csrftoken},
                      success: function(response) {
                          // console.log(response.error.status)
                          if(response.error.status=="4002"){
                              var info="User already registered.."
                              $('#signup-err-msg').text('Email ID already registered...')
                          }
                          else{
                              alert("User account created successfully..")
                              window.location.reload()
                            }
                          

                      },
                      error: function(err) {
                        // console.log(err)
                        self.validate_message = '';
                        self.WarningClass = true;
                        self.WarningStyle = {'display': 'block'};
                        self.spinner.hide();
                      }
                      });

            }
      }
      else
      {
          $('#signup-err-msg').text(err_msg);
          $('#signup-err-msg').focus();
      }

    
    
  
}
otpSubmit(value){
  var self = this;
  self.otpmessage="";
   self.otpmessage="Invalid OTP";
   $(".warningotp").html("");
  let otp=value.otp;
  // console.log('Actual otp and entered otp',otp)
  if(otp){
    var csrftoken = self.getCookie('csrf_token');
    let email=self.odooemailid;
    if (email === null || email === 'null' || !email){
      email = self.registered_email
    }
    let args = {'email':email,'otp':otp};
    $.ajax({
      beforeSend: function(xhr, settings) {
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      },
      method: "POST",
      url: "/web/payment_portal/otp_valid/",
      dataType: "json",
      data : {data:JSON.stringify(args),csrf_token: csrftoken},
      success: function(response) {
          // console.log(response.error.status)
          if(response.error.status=="4002"){
            $("<br/><span><t>"+self.otpmessage+"</t></span>").appendTo(".warningotp")
            return false;
          }
          else{
            if(self.forgetpasswordotp==true){
              self.OTPClass = false;
              self.OTPStyle = {'display': 'none'};
              self.ConfirmPasswordClass = true;
              self.confirmPasswordStyle = {'display': 'block'};
              $('#otp-verify-form').hide();
              $('#get-password-form').show();
            }
            else{ 
            self.router.navigate(['/giftcardregistered'])
            }
          }
      },
      error: function(err) {
        $("<br/><span><t>"+self.otpmessage+"</t></span>").appendTo(".warningotp")
        return false;
      }
      });  
  }
  else{
  $("<br/><span><t>"+self.otpmessage+"</t></span>").appendTo(".warningotp")
      return false;
  }
}

    
 loginSubmit(value){
 var self = this;
 self.buttonstyle = {'background-color':'#33b5e5'};
//  console.log(value.mobile_number,value.password,"kkkokoiuu")
 self.msg=""
 let mobile_number=value.mobile_number;
 let session_time = new Date()
 this.appService.setMobileNumber(mobile_number);
  let password=value.password;
  if (!mobile_number || !password){
  if(!mobile_number){
   self.msg +=" Mobile Number,"
   $('#login-email').focus()
   return
  }
  if(!password){
  self.msg +=" Password,"
  $('#login-password').focus()
  }
  this.validate_message = "Please Enter "+self.msg+" to Login";

    $(".info").html("");

    $("<br/><span style='color:red'><t>"+this.validate_message+"</t></span>").appendTo(".info")
    return false;
    }
    var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }
    if (mobile_number || password){
    let args = {'session': session_time,'password':password,'mobile_number':mobile_number };
    var csrftoken = self.getCookie('csrf_token');
    self.spinner.show();  
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/login/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) { 
            if(response.error.status=="4002"){
                $(".info").html("");
                self.loginForm={
                mobileNumber:"",
                password:""
                };
                var info="Invalid Credentials"
                alert(info)
                $("<br/><span style='color:red;'><t>"+info+"</t></span>").appendTo(".info")
                self.spinner.hide();
            }
            
            else{
                //localStorage.setItem('isspayandredeem',"false");
                self.loginForm={
                mobileNumber:"",
                password:""
                }
                // console.log(response.data.country[0].currency)
                localStorage.setItem('odoomobilenum', response.data.MobileNumber);
                localStorage.setItem('mobile',response.data.MobileNumber);
                // localStorage.setItem('odooemailid', response.data.EmailId);
                self.odooemailid = response.data.EmailId
                localStorage.setItem('otp', response.data.otp);
                localStorage.setItem('session_valid_key', response.data.session_key);
                localStorage.setItem('country_code', response.data.country[0].country_code);
                localStorage.setItem('country_name', response.data.country[0].country_name);
                localStorage.setItem('currency', response.data.country[0].currency);
                // console.log(localStorage.getItem('session_valid_key'))
                self.ActualOTP=localStorage.getItem('otp');
                self.LoginClass = false;
                self.LoginStyle = {'display': 'none'};
                localStorage.setItem('otp_enabled', response.data.otp_enabled)
                
                self.otp_enabled=localStorage.getItem('otp_enabled');
                // console.log(self.otp_enabled,"otp enabled",localStorage.getItem('otp_enabled'))
                self.spinner.hide()
                if(self.otp_enabled =='True')
                {
                 self.router.navigate(['/giftcardregistered'])
                }
                else{
                self.OTPClass = true;
                self.OTPStyle = {'display': 'block'};
                self.otpmessage="";
                document.getElementById("otp-validation-btn").click();
                 self.otpmessage="OTP is sent to your registered email address. Please enter the OTP received";
                 $(".warningotp").html("");
                 $("<br/><span><t>"+self.otpmessage+"</t></span>").appendTo(".warningotp")
                 }
                 $('.modal-backdrop').hide();
                self.spinner.hide();
                

                }
            },
            error: function(err) {
              // console.log(err)
              self.validate_message = '';
              self.WarningClass = true;
              self.WarningStyle = {'display': 'block'};
              self.spinner.hide();
            }
        });


    } 

 }


registeredemailSubmit(value){
  var self=this;
  let email=value.email
  self.msg=""
  if(!email){
  self.msg +=" Email "
  self.validate_message =""
  self.validate_message = "Please Enter "+self.msg;

    $(".warningregistredemail").html("");

    $("<br/><span style='color:red'><t>"+self.validate_message+"</t></span>").appendTo(".warningregistredemail")
    return false;
  }
  if(email){
  self.odooemailid = ''
  // localStorage.setItem('odooemailid', null)  
  self.registered_email=email
  var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }
    let args = {'session': session_id,'email':email };
    var csrftoken = self.getCookie('csrf_token');
    this.spinner.show();  
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/registeredchecking/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
          
            self.registredEmailForm={
              email:""
              };
            if(response.error.status=="4002"){
              $(".warningregistredemail").html("");
              var info="Email not registered"
              $("<br/><span style='color:red;'><t>"+info+"</t></span>").appendTo(".warningregistredemail")
              self.spinner.hide();
            }
            else{
              localStorage.setItem('otp', response.data.otp)
              self.ActualOTP=response.data.otp;
              self.RegisteredEmailClass = false;
              self.registeredEmailStyle = {'display': 'none'};
              self.forgetpasswordotp = true;
              self.OTPClass = true;
              self.OTPStyle = {'display': 'block'};
              $('#get-email-form').hide();
              $('#otp-verify-form').show();
              self.otpmessage="";
             self.otpmessage="OTP is sent to your registered email address. Please enter the OTP received";
             $(".warningotp").html("");
             $("<br/><span><t>"+self.otpmessage+"</t></span>").appendTo(".warningotp")
            }





            self.spinner.hide();
            },
            error: function(err) {
              // console.log(err)
              self.validate_message = '';
              self.WarningClass = true;
              self.WarningStyle = {'display': 'block'};
              self.spinner.hide();
            }
            });
  }

}
confirmPasswordSubmit(value){
var self=this;
// console.log(value.password,value.confirm_password)
  let password=value.password
  let confirm_password=value.confirm_password
  // console.log(password,confirm_password)
  if(!password || !confirm_password){
  self.validate_message =""
  self.validate_message = "Please Enter the Correct Password";

    $(".warningconfirmpassword").html("");

    $("<br/><span style='color:red'><t>"+self.validate_message+"</t></span>").appendTo(".warningconfirmpassword")
    return false;
  }
  if(password!=confirm_password){
  self.validate_message =""
  self.validate_message = "Please Enter Correct Password";

    $(".warningconfirmpassword").html("");

    $("<br/><span style='color:red'><t>"+self.validate_message+"</t></span>").appendTo(".warningconfirmpassword")
    return false;
  }
  if (password.length < 8){
    
    this.validate_message = "Password must be minimum 8 character";
    $('#id_warn_message').hide();
    $(".warningconfirmpassword").html("");

    $("<br/><span><t>"+this.validate_message+"</t></span>").appendTo(".warningconfirmpassword")
    return false;
    
    }
    else{
    let validPassword = self.validatePassword(password)
    // console.log('password validatedddddddddddddd',validPassword)
    if (validPassword == false){
        this.validate_message = "Password should contain Upper Case Letter, a Number and one special character from this list !#$%&?@";
        $('#id_warn_message').hide();
        $(".warningconfirmpassword").html("");

        $("<br/><span><t>"+this.validate_message+"</t></span>").appendTo(".warningconfirmpassword")
        return false;
    }
    
    }

  if(password && confirm_password){
  
  var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }
    
    let args = {'session': session_id,'email':self.registered_email,'password':password,'confirm_password':confirm_password };
    var csrftoken = self.getCookie('csrf_token');
    this.spinner.show();  
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/forgotpassword/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            self.confirmpasswordForm={
            password:"",
            confirmPassword:""
            };
            if(response.error.status=="4002"){
            $(".warningregistredemail").html("");
            var info="Password not updated.Please try again later.."
            alert(info)
            //$("<br/><span style='color:red;'><t>"+info+"</t></span>").appendTo(".warningconfirmpassword")
            self.spinner.hide();
            }
            else{
    
            self.ConfirmPasswordClass = false;
            self.confirmPasswordStyle = {'display': 'none'};
            self.forgetpasswordotp = false;
            self.LoginClass = true;
            self.LoginStyle = {'display': 'block'};
            $(".info").html("");
            var info="Password updated successfully"
            //$("<br/><span style='color:#000080;'><t>"+info+"</t></span>").appendTo(".info")
            alert(info)
            self.spinner.hide();
            
            self.otpForm={
            otp:""
            }
            
            }




            self.spinner.hide();
            window.location.reload();
            },
            error: function(err) {
              // console.log(err)
              self.validate_message = '';
              self.WarningClass = true;
              self.WarningStyle = {'display': 'block'};
              self.spinner.hide();
            }
            });
  }
  
}

resendotp = function(){
var self =this;
let emailid=self.odooemailid;
let emailreg_email = self.registered_email
if (emailreg_email && !emailid){
  emailid = emailreg_email
}
let otp=self.ActualOTP
var session_id='';
    if(this.getCookie('kalyan_id')!=null){
      session_id=this.getCookie('kalyan_id');Date
    }
    let args = {'session': session_id,'email':emailid,'otp':otp};
    var csrftoken = self.getCookie('csrf_token');
    this.spinner.show();  
    $.ajax({
            beforeSend: function(xhr, settings) {
              xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            method: "POST",
            url: "/web/payment_portal/resendotp/",
            dataType: "json",
            data : {data:JSON.stringify(args),csrf_token: csrftoken},
            success: function(response) {
            if(response.error.status=="4002"){
            self.otpmessage="";
             self.otpmessage="Sorry some problem with OTP";
             $(".warningotp").html("");
             $("<br/><span><t>"+self.otpmessage+"</t></span>").appendTo(".warningotp")
            }
            else{
            localStorage.setItem('otp', response.data.otp)
            self.ActualOTP=response.data.otp;
            self.otpmessage="";
             self.otpmessage="OTP is sent to your registered email address. Please enter the OTP received";
             $(".warningotp").html("");
             $("<br/><span><t>"+self.otpmessage+"</t></span>").appendTo(".warningotp")
            }
            self.spinner.hide();
            },
            error: function(err) {
              // console.log(err)
              self.validate_message = '';
              self.WarningClass = true;
              self.WarningStyle = {'display': 'block'};
              self.spinner.hide();
            }
            });
  
}




 }


