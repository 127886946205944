<app-home-navbar></app-home-navbar>
<div>
  <!-- Login Modal -->
  <div
    class="modal fade form-modal"
    id="forgotpassword"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog max-width-px-840 position-relative">
      <button
        type="button"
        class="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
        data-dismiss="modal"
      >
        <i class="fas fa-times"></i>
      </button>
      <div class="login-modal-main bg-white rounded-8 overflow-hidden">
        <div class="row no-gutters">
          <div class="col-lg-5 col-md-6">
            <div
              class="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts"
            >
              <div class="pb-9">
                <h3
                  class="font-size-8 text-white line-height-reset pb-4 line-height-1p4"
                >
                  Forgot Password
                </h3>
                <p class="mb-0 font-size-4 text-white">
                  Enter registered email (or) mobile number to continue..
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-6">
            <div class="bg-white-2 h-100 px-11 pt-11 pb-7">
              <div class="or-devider">
                <span class="font-size-3 line-height-reset"
                  ><img src="/assets/images/logo.png"
                /></span>
              </div>
              <form
                #formE="ngForm"
                id="get-email-form"
                (ngSubmit)="registeredemailSubmit(formE.value)"
                class=""
                data-backdrop="static"
              >
                <div class="modal-body text-center">
                  <!-- <button  (click)="onNoregisteredemailclick()" [ngStyle]='LoginXotpStyle' class="btn btn-danger" id="forgot-email-close"><i class="fa fa-close"></i></button> -->
                  <!-- <img src="/assets/images/logo.png"> -->

                  <!-- <br/> -->
                  <div
                    style="color: red; font-size: 18px"
                    class="warningregistredemail text-center"
                  ></div>
                  <p class="text-center mt-5 font-weight-semibold">
                    Password Reset
                  </p>
                  <!-- style="margin-top: 150px" -->
                  <div class="modal-dialog" role="document">
                    <div class="form-group m-t-20">
                      <div class="col-xs-12">
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          [(ngModel)]="registredEmailForm.email"
                          placeholder="Enter Registered Email"
                          autofocus=""
                        />
                      </div>

                      <div
                        class="form-group text-center m-t-20"
                        style="margin-top: 20px"
                      >
                        <div class="col-xs-12">
                          <button
                            class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                            style="background-color: #1e90ff"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <form
                #formO="ngForm"
                (ngSubmit)="otpSubmit(formO.value)"
                class=""
                id="otp-verify-form"
              >
                <div class="modal-body text-center">
                  <div
                    style="color: red; font-size: 18px"
                    class="warningotp text-center"
                  ></div>
                  <p class="text-center mt-5 font-weight-semibold">
                    OTP Validation
                  </p>
                  <div class="modal-dialog" role="document">
                    <div class="form-group m-t-20">
                      <div class="col-xs-12">
                        <input
                          class="form-control"
                          type="text"
                          name="otp"
                          [(ngModel)]="otpForm.otp"
                          placeholder="Enter OTP"
                          autofocus=""
                        />

                        <a
                          (click)="resendotp()"
                          style="font-size: 14px; color: blue; cursor: pointer"
                          class="text-info float-right"
                          >Resend OTP?</a
                        >
                        <br />
                      </div>
                      <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                          <button
                            class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                            style="background-color: #1e90ff"
                            type="submit"
                          >
                            Verify OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <form
                #formC="ngForm"
                (ngSubmit)="confirmPasswordSubmit(formC.value)"
                class=""
                data-backdrop="static"
                id="get-password-form"
              >
                <div class="modal-body text-center">
                  <div
                    style="color: red; font-size: 18px"
                    class="warningconfirmpassword text-center"
                  ></div>
                  <!-- style="margin-top: 150px" -->
                  <p class="text-center mt-5 font-weight-semibold">
                    Reset Password
                  </p>
                  <p>
                    Password should contain minimum 8 letters,one uppercase,one
                    numeric and one special character..
                  </p>
                  <div class="modal-dialog" role="document">
                    <div class="form-group m-t-20">
                      <div class="form-group m-t-20">
                        <div class="col-xs-12">
                          <div
                            style="font-size: 10px; display: none; color: red"
                            id="id_warn_message"
                          >
                            Password should be Minimum 8 character
                          </div>
                          <div class="position-relative">
                            <input
                              id="id_password"
                              class="form-control val"
                              [type]="forgetPassword ? 'text' : 'password'"
                              name="password"
                              [(ngModel)]="confirmpasswordForm.password"
                              placeholder="Password"
                              (keyup)="passwordValidation($event)"
                              ng-pattern-restrict="^[a-zA-Z0-9]+"
                              autofocus=""
                            />
                            <span
                              class="show-password pos-abs-cr fas mr-6 text-black-2"
                              (click)="forgetpass1()"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group m-t-30">
                        <div class="col-xs-12">
                          <div class="position-relative">
                            <div
                              style="font-size: 10px; display: none; color: red"
                              id="id_confirm_warn_message"
                            >
                              Password should be Minimum 8 character
                            </div>
                            <input
                              id="id_confirm_password"
                              class="form-control val"
                              [type]="forgetPass ? 'text' : 'password'"
                              name="confirm_password"
                              [(ngModel)]="confirmpasswordForm.confirmPassword"
                              placeholder="Confirm Password"
                              (keyup)="passwordValidation($event)"
                            />
                            <span
                              class="show-password pos-abs-cr fas mr-6 text-black-2"
                              (click)="forget2()"
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group text-center m-t-20"
                        style="margin-top: 20px"
                      >
                        <div class="col-xs-12">
                          <button
                            class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                            style="background-color: #1e90ff"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sign Up Modal -->
  <div class="modal fade" id="signup" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog max-width-px-840 position-relative">
      <!-- <button type="button" class="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10" data-dismiss="modal"><i class="fas fa-times"></i></button> -->
      <div class="login-modal-main bg-white rounded-8 overflow-hidden">
        <div class="row no-gutters">
          <div class="col-lg-5 col-md-6">
            <div
              class="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts"
            >
              <div class="pb-9">
                <h3
                  class="font-size-8 text-white line-height-reset pb-4 line-height-1p4"
                >
                  Sign Up
                </h3>
                <p class="mb-0 font-size-4 text-white">
                  Create your account to register new gift cards
                </p>
              </div>
              <!--  <div class="border-top border-default-color-2 mt-auto">
                  <div class="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div class="pt-5 px-9">
                      <h3 class="font-size-7 text-white">
                        295
                      </h3>
                      <p class="font-size-3 text-white gr-opacity-5 line-height-1p4">New jobs
                        posted today</p>
                    </div>
                    <div class="pt-5 px-9">
                      <h3 class="font-size-7 text-white">
                        14
                      </h3>
                      <p class="font-size-3 text-white gr-opacity-5 line-height-1p4">New companies
                        registered</p>
                    </div>
                  </div>
                </div> -->
            </div>
          </div>
          <div class="col-lg-7 col-md-6">
            <div class="bg-white-2 h-100 px-11 pt-11 pb-7">
              <div class="text-center">
                <img src="/assets/images/logo.png" />
              </div>
              <br />
              <p class="text-center text-danger" id="signup-err-msg"></p>
              <form
                #formP="ngForm"
                (ngSubmit)="formSubmit(formP.value)"
                class="loginppp"
                role="dialog"
                [ngClass]="{
                  show: SignupClass == true,
                  '': SignupClass == false
                }"
                [ngStyle]="SignupStyle"
                id="signup-form"
              >
                <div class="form-group">
                  <label
                    for="email2"
                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >E-mail</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="Email"
                    [(ngModel)]="signupForm.email"
                    required=""
                    id="signup-email"
                  />
                </div>
                <div class="form-group">
                  <label
                    for="country2"
                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >Country</label
                  >
                  <select
                    class="form-control text-black"
                    id="signup-country"
                    name="signup_country"
                    (change)="OnchangeCountry()"
                    required=""
                  >
                    <option value="" selected="" disabled="">
                      --Choose Country--
                    </option>
                    <option
                      value="{{ c.country_code }}"
                      *ngFor="let c of countryselection"
                    >
                      {{ country.country_name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label
                    for="mobile2"
                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >Mobile</label
                  >
                  <input
                    class="form-control val"
                    type="text"
                    name="mobile_number"
                    [(ngModel)]="signupForm.mobileNumber"
                    placeholder="Mobile Number"
                    id="signup-mobile"
                    maxlength="{{ mobile_input_digit }}"
                    required=""
                  />
                </div>
                <div class="form-group">
                  <label
                    for="password2"
                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >Password</label
                  >
                  <div class="position-relative">
                    <input
                      id="id_password"
                      class="form-control val"
                      [type]="showPassword ? 'text' : 'password'"
                      name="password"
                      [(ngModel)]="signupForm.password"
                      placeholder="Password"
                      (keyup)="passwordValidation($event)"
                      ng-pattern-restrict="^[a-zA-Z0-9]+"
                      id="signup-password"
                      required=""
                    />
                    <span
                      class="show-password pos-abs-cr fas mr-6 text-black-2"
                      (click)="togglePasswordVisibility()"
                    ></span>
                    <!-- <mat-icon matSuffix>visibility</mat-icon> -->
                  </div>
                </div>
                <div class="form-group">
                  <label
                    for="password23"
                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >Confirm Password</label
                  >
                  <div class="position-relative">
                    <input
                      id="id_confirm_password"
                      class="form-control val"
                      [type]="showPass ? 'text' : 'password'"
                      name="confirm_password"
                      [(ngModel)]="signupForm.confirmPassword"
                      placeholder="Confirm Password"
                      (keyup)="passwordValidation($event)"
                      id="signup-password"
                      required=""
                    />
                    <span
                      class="show-password pos-abs-cr fas mr-6 text-black-2"
                      (click)="showpa()"
                    ></span>
                  </div>
                </div>
                <ul
                  class="ml-3 mt-2 alert-danger pt-5 pb-5"
                  id="password-pattern"
                >
                  <li class="len-case">
                    Contains minimum 8 letters.
                    <i
                      class="fa fa-check-circle text-success"
                      id="len-check"
                    ></i>
                  </li>
                  <li class="up-case">
                    Contains one upper case letter.
                    <i
                      class="fa fa-check-circle text-success"
                      id="up-check"
                    ></i>
                  </li>
                  <li class="nu-case">
                    Contains one numeric letter.
                    <i
                      class="fa fa-check-circle text-success"
                      id="nu-check"
                    ></i>
                  </li>
                  <li class="sp-case">
                    Contains one special characters which must be in this
                    (!,@,#,$,-,_).
                    <i
                      class="fa fa-check-circle text-success"
                      id="sp-check"
                    ></i>
                  </li>
                </ul>
                <!-- <div class="form-group d-flex flex-wrap justify-content-between mb-1">
                    <label for="terms-check2" class="gr-check-input d-flex  mr-3">
                      <input class="d-none" type="checkbox" id="terms-check2">
                      <span class="checkbox mr-5"></span>
                      <span class="font-size-3 mb-0 line-height-reset d-block">Agree to the <a href="" class="text-primary">Terms & Conditions</a></span>
                    </label>
                    <a href="" class="font-size-3 text-dodger line-height-reset">Forget Password</a>
                  </div> -->
                <div class="form-group mb-8">
                  <button
                    class="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                  >
                    Sign Up
                  </button>
                </div>
                <!-- <p class="font-size-4 text-center heading-default-color">Don’t have an account? <a href="" class="text-primary">Create a free account</a></p> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <label for="dateOfBirth">Date of birth</label>
          <div class="input-group">
            <input
              id="dateOfBirth"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp"
              ngbDatepicker
              #dp="ngbDatepicker"
            />
            <button
              class="btn btn-outline-secondary bi bi-calendar3"
              (click)="dp.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.close('Save click')"
      >
        Save
      </button>
    </div>
  </ng-template>

  <button class="btn btn-lg btn-outline-primary" (click)="open(content)">
    Launch demo modal
  </button>

  <hr />

  <pre *ngIf="closeResult">{{ closeResult }}</pre>

  <div
    class="modal fade form-modal"
    id="otp-validation"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog max-width-px-840 position-relative">
      <!-- <button type="button" class="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10" data-dismiss="modal"><i class="fas fa-times"></i></button> -->
      <div class="login-modal-main bg-white rounded-8 overflow-hidden">
        <div class="row no-gutters">
          <div class="col-lg-5 col-md-6">
            <div
              class="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts"
            >
              <div class="pb-9">
                <h3
                  class="font-size-8 text-white line-height-reset pb-4 line-height-1p4"
                >
                  OTP Validation
                </h3>
                <p class="mb-0 font-size-4 text-white">
                  Please enter OTP which is sent to your registered email..
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-6">
            <div class="bg-white-2 h-100 px-11 pt-11 pb-7">
              <div class="or-devider">
                <span class="font-size-3 line-height-reset"
                  ><img src="/assets/images/logo.png"
                /></span>
              </div>
              <form
                #formO="ngForm"
                (ngSubmit)="otpSubmit(formO.value)"
                class=""
              >
                <div class="modal-body text-center">
                  <div
                    style="color: red; font-size: 18px"
                    class="warningotp text-center"
                  ></div>
                  <p class="text-center mt-5 font-weight-semibold">
                    OTP Validation
                  </p>
                  <div class="modal-dialog" role="document">
                    <div class="form-group m-t-20">
                      <div class="col-xs-12">
                        <input
                          class="form-control"
                          type="text"
                          name="otp"
                          [(ngModel)]="otpForm.otp"
                          placeholder="Enter OTP"
                          autofocus=""
                        />

                        <a
                          (click)="resendotp()"
                          style="font-size: 14px; color: blue; cursor: pointer"
                          class="text-info float-right"
                          >Resend OTP?</a
                        >
                        <br />
                      </div>
                      <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                          <button
                            class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                            style="background-color: #1e90ff"
                            type="submit"
                          >
                            Verify OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Header start end -->
  <!-- Hero Area -->
  <div
    class="position-relative z-index-1 bg-squeeze dark-mode-texts screen-div"
  >
    <!-- <div class="container position-static">
        <div class="row position-relative align-items-center position-static">
          <div class="col-xxl-7 col-xl-8 col-lg-9 pt-lg-23 pb-lg-33 pb-md-28 pb-xs-26 pb-29 pt-md-20" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
            <div class="row">
              <div class="col-xxl-8 col-xl-7 col-md-8 col-sm-10">
                <div class="text-primary font-size-5 font-weight-semibold mb-7">
                  #4923 jobs are available right now
                </div>
                <h1 class="font-size-11 mb-9 text-black-2">Find the most exciting jobs.</h1>
                <p class="font-size-5">Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative to</p>
              </div>
            </div>
          </div>
          
        </div>
      </div> -->
    <!-- <div class="pos-abs-tr h-100"> -->
    <div class="row">
      <div class="col-lg-8 img-div">
        <img
          src="/assets/images/kalyan-background.jpg"
          alt=""
          class="h-100"
          width="100%"
        />
      </div>
      <div class="col-lg-4 pt-15 pr-5 login-div">
        <div class="card mr-10">
          <div class="card-body">
            <div class="text-center"><img src="/assets/images/logo.png" /></div>
            <br />
            <p class="font-weight-semibold text-center">Login</p>
            <form
              #formL="ngForm"
              (ngSubmit)="loginSubmit(formL.value)"
              class="loginppp"
            >
              <div class="form-group">
                <label
                  for="email"
                  class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >E-mail</label
                >
                <input
                  type="email"
                  class="form-control"
                  name="mobile_number"
                  [(ngModel)]="loginForm.mobileNumber"
                  placeholder="Email (or) Mobile Number"
                  id="login-email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label
                  for="password"
                  class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >Password</label
                >
                <div class="position-relative">
                  <input
                    type="password"
                    class="form-control"
                    name="password"
                    [(ngModel)]="loginForm.password"
                    placeholder="Password"
                    id="login-password"
                    autocomplete="off"
                  />
                  <!-- <a href="#" class="show-password pos-abs-cr fas mr-6 text-black-2" data-show-pass="password"></a> -->
                </div>
              </div>
              <!-- <div class="form-group d-flex flex-wrap justify-content-between"> -->
              <!-- <label for="terms-check" class="gr-check-input d-flex  mr-3">
                      <input class="d-none" type="checkbox" id="terms-check">
                      <span class="checkbox mr-5"></span>
                      <span class="font-size-3 mb-0 line-height-reset mb-1 d-block">Remember password</span>
                    </label> -->
              <a
                href="javacript:"
                class="font-size-3 text-dodger line-height-reset float-right text-primary font-weight-semibold"
                data-toggle="modal"
                data-target="#forgotpassword"
                >Forget Password ?</a
              >
              <!-- </div> -->
              <br />
              <div class="form-group mb-8">
                <button
                  class="btn btn-danger btn-medium w-100 rounded-5 text-uppercase"
                >
                  Log in
                </button>
                <a
                  href="javacript:"
                  data-toggle="modal"
                  data-target="#otp-validation"
                  class="d-none"
                  id="otp-validation-btn"
                  >asdasd</a
                >
              </div>
              <div class="form-group mb-8">
                <button
                  class="btn btn-outline-primary btn-medium w-100 rounded-5 text-uppercase"
                  data-toggle="modal"
                  onclick="checkfade()"
                  type="button"
                  data-target="#signup"
                >
                  Sign Up
                </button>
              </div>
              <!-- <p class="font-size-4 text-center heading-default-color text-black-2">Don’t have an account? <a href="" class="text-primary font-weight-semibold">Sign Up</a></p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <!-- Hero Area -->
  <!-- category Area -->
</div>
