export class SignupForm{
	email: string = null;
    mobileNumber: string = null;
    password: string = null;
    confirmPassword: string = null;
}

export class LoginForm{
    mobileNumber: string = null;
    password: string = null;
    
}
export class OtpForm{
	otp: string = null;
}

export class RegistredEmailForm{
	email: string = null;
}

export class ConfirmPasswordForm{
	password: string = null;
    confirmPassword: string = null;
}

export class SchemeList{
	
}

export class NewGiftcard{
	monthdenomamt: number = null;
	tenure: number = null;
	persfirstname: string = null;
	perslastname: string = null;
	persdob: string = null;
	persbeneficiary: string = null;
	relswithnominee: string = null;
	relsfirstname: string = null;
	relslastname: string = null;
	relsmobile: string = null;
	relsemail: string =null;
	modeofpay: string = null;
	paymentchannel: string =null;
	giftcardtype: string =null;
}

export class GiftcardTopup{
    modeofpay: string = null;
	paymentchannel: string =null;
	
}
